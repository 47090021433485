import React from "react";

function Community() {
  return (
    <div className="row col-lg-12 p" style={{ marginTop: "180px" }}>
      <div className="g-title">Meet the worldwide community</div>
      <p className="g-p" style={{ fontSize: "22px" }}>
        Join a fast-growing community of developers and innovators connected all
        over the world, building the new era of scientific data.
      </p>
      <div className="row col-lg-12 mt-4 acc">
        <div className="col-lg-4">
          <div className="g-card row" style={{ padding: "30px 30px" }}>
            <div
              className="d-flex mb-3"
              style={{ alignItems: "center", gap: "15px" }}
            >
              <span>
                <img
                  src="https://sale.starchain.dev/assets/images/svg-icons/send.svg"
                  alt=""
                  srcset=""
                />
              </span>
              <span
                style={{ color: "white", fontSize: "30px", fontWeight: "500" }}
              >
                Announcements
              </span>
            </div>
            <div style={{ paddingLeft: "70px", fontSize: "18px",color:'#e1e1e1' }}>
              Join our announcements group to stay up to date with the progress
              of AMEYAA NXT..
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="g-card row mb-3" style={{ padding: "30px 30px" }}>
            <div
              className="d-flex mb-3"
              style={{ alignItems: "center", gap: "15px" }}
            >
              <span>
                <img
                  src="https://sale.starchain.dev/assets/images/svg-icons/send.svg"
                  alt=""
                  srcset=""
                />
              </span>
              <span
                style={{ color: "white", fontSize: "30px", fontWeight: "500" }}
              >
                Community Chat
              </span>
            </div>
            <div style={{ paddingLeft: "70px", fontSize: "18px",color:'#e1e1e1' }}>
              Ask questions and chat with the community on Telegram.
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="g-card row" style={{ padding: "30px 30px" }}>
            <div
              className="d-flex mb-3"
              style={{ alignItems: "center", gap: "15px",color:'#e1e1e1' }}
            >
              <span>
                <img
                  src="https://sale.starchain.dev/assets/images/svg-icons/twitter-circle.svg"
                  alt=""
                  srcset=""
                />
              </span>
              <span
                style={{ color: "white", fontSize: "30px", fontWeight: "500" }}
              >
                X (ex Twitter)
              </span>
            </div>
            <div style={{ paddingLeft: "70px", fontSize: "18px" }}>
              Follow on X to join our community and learn more
              about the ecosystem.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Community;
