import React from "react";

function Ecosystem() {
  return (
    <div
      className="p row col-lg-12 mt-5"
      style={{
        marginBottom: "180px",
      }}
    >
      <div className="g-title mt-3">Join Our Ecosystem</div>
      <p className="g-p">
        Ameyaa NXT’s Wealth Creation Program is designed to empower users to
        build and grow their wealth through innovative tools, strategic
        insights, and robust support. Here’s how our program works:
      </p>

      <div className="row col-lg-12 eco">
        <div className="col-lg-4">
          <div>
            <p className="become">Strategic Investment</p>
            <p className="contributor"> Opportunities</p>
            <p className="star">
              Access to advanced AI algorithms that analyze market trends and
              provide personalized investment strategies. These insights help
              optimize your investment decisions and enhance potential returns
            </p>
            <p className="apply">Apply now</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div>
            <p className="become">Token Utilization </p>
            <p className="contributor">and Growth</p>
            <p className="star">
              Earn Anxt tokens through participation in the platform, staking,
              and trading activities. These tokens can be utilized for accessing
              premium features, participating in governance, and receiving
              rewards.
            </p>
            <p className="apply">Apply now</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div>
            <p className="become">Education</p>
            <p className="contributor">and Resources</p>
            <p className="star">
              Access to a wealth of educational resources, including webinars,
              guides, and expert articles, designed to enhance your
              understanding of investment strategies and wealth management.k
            </p>
            <p className="apply">Apply now</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ecosystem;
