import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <section className="">
      <div className="header">
        <Link to="/">
          <div className="logo" style={{ width: "180px", height: "80px" }}>
            <img
              src="/image/AmeyaaLogo.jpeg"
              alt=""
              srcset=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Link>
        <div className="menu">
          <Link to="/token">User Details</Link>
          <Link to="/reward">Referral</Link>
          <a href="javascript:void(0)">FAQS</a>
        </div>
        <div className="presale-btn">
          <button className="btn">Join Presale</button>
        </div>
      </div>

      <nav class="navbar navbar-dark bg-dark fixed-top">
        <div class="container-fluid">
          <div className="logo" style={{ width: "180px", height: "80px" }}>
            <img
              src="/image/AmeyaaLogo.jpeg"
              alt=""
              srcset=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="offcanvas offcanvas-end text-bg-dark bg-dark"
            tabindex="-1"
            id="offcanvasDarkNavbar"
            aria-labelledby="offcanvasDarkNavbarLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">
                Dark offcanvas
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <ul>
                <li
                  className="menu"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link to="/token" className="text-white">
                    User Details
                  </Link>
                </li>
                <li className="menu">
                  <Link
                    to="/reward"
                    className="text-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Referral
                  </Link>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
}

export default Navbar;
