import React from "react";

function System() {
  return (
    <div className="row col-lg-12 p">
      <div className="ecosystem">Ecosystem</div>
      <div className="g-title">How AMEYAA NXT works</div>
      <p className="g-p col-lg-8">
        By integrating advanced AI technology, Ameyaa NXT helps analyze market
        data, offering personalized recommendations to maximize your trading
        potential.By integrating advanced AI technology, Ameyaa NXT helps
        analyze market data, offering personalized recommendations to maximize
        your trading potential.
      </p>
      <div className="row col-lg-12 system">
        <div className="col-lg-4">
          <div className="g-card">
            <div className="number mb-3">1</div>
            <div
              style={{
                fontSize: "30px",
                marginBottom: "40px",
              }}
            >
              Analysis your Investment
            </div>
            <p className="g-p" style={{ fontSize: "20px", color: "#e1e1e1" }}>
              Analyze Your Investment with Ameyaa NXT to take full control of
              your financial growth. Our AI-powered platform continuously
              monitors and evaluates your trading activities, providing detailed
              insights into market trends, asset performance, and potential
              risks.By leveraging real-time data, Ameyaa NXT offers tailored
              strategies to optimize your returns, ensuring you stay informed
              and make well-calculated decisions.
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="g-card">
            <div className="number mb-3">2</div>
            <div
              style={{
                fontSize: "30px",
                marginBottom: "40px",
              }}
            >
              Progress Tracking
            </div>
            <p className="g-p" style={{ fontSize: "20px", color: "#e1e1e1" }}>
              Progress Tracking with Ameyaa NXT allows you to stay on top of
              your trading and investment performance. Our app provides
              real-time updates and detailed reports on how your investments are
              growing, tracking key metrics such as profit margins, portfolio
              growth, and market trends. You can set goals and milestones within
              the app, and the AI system will continuously monitor your
              progress, offering insights and adjustments to help you stay on
              course.
            </p>
          </div>
        </div>{" "}
        <div className="col-lg-4">
          <div className="g-card">
            <div className="number mb-3">3</div>
            <div
              style={{
                fontSize: "30px",
                marginBottom: "40px",
              }}
            >
              Fraud Preventetion
            </div>
            <p className="g-p" style={{ fontSize: "20px", color: "#e1e1e1" }}>
              Fraud Prevention is at the core of Ameyaa NXT’s mission. Our
              platform is equipped with advanced AI-driven security features
              designed to protect your investments from fraudulent activities.
              By continuously monitoring transaction patterns, identifying
              suspicious behavior, and implementing robust encryption protocols,
              Ameyaa NXT ensures a secure trading environment.
            </p>
          </div>
        </div>
      </div>

      <div
        className="row col-lg-8  g-button"
        style={{position:'relative',top:'50px'}}
      >
        Join exclusive Presale Now
      </div>
    </div>
  );
}

export default System;
