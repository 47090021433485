import React, { useEffect, useState } from "react";

function Solution() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      setMousePosition({ x: clientX, y: clientY });
      document.documentElement.style.setProperty("--x", clientX);
      document.documentElement.style.setProperty("--y", clientY);
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="solution p  mb-3 ">
      <div className=" sol-title ms-2">Our Solution</div>
      <div className="row col-lg-12 ">
        <div className="col-lg-4 my-3">
          <div className="solution-card">
            <div className="icon">
              <img
                // src="/image/cube.svg"
                src="/image/ai1.jpg"
                alt=""
              />
            </div>
            <div className="data">Exchange Listings</div>

            <p className="m-desc" style={{ color: "#e1e1e1" }}>
              Secure listings on reputable cryptocurrency exchanges to enhance
              liquidity and market accessibility. Ensure that the Anxt token is
              available for trading across multiple platforms.
            </p>

            <div className="learn">
              Learn more{" "}
              <img
                src="https://sale.starchain.dev/assets/images/svg-icons/redirect.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 my-3">
          <div className="solution-card">
            <div className="icon">
              <img
                src="/image/ai2.jpg"
                // src="/image/telescope.svg"
                alt=""
              />
            </div>
            <div className="data">Revenue Generation</div>

            <p className="m-desc" style={{ color: "#e1e1e1" }}>
              Ensure that the Anxt token is fully integrated into the Ameyaa NXT
              platform, offering users benefits such as reduced transaction
              fees, access to premium features, and rewards based on their
              investment.
            </p>

            <div className="learn">
              Learn more{" "}
              <img
                src="https://sale.starchain.dev/assets/images/svg-icons/redirect.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 my-3">
          <div className="solution-card">
            <div className="icon">
              <img
                src="/image/ai3.jpg"
                // src="/image/trophy.svg"
                alt=""
              />
            </div>
            <div className="data">Incentive Programs</div>

            <p className="m-desc" style={{ color: "#e1e1e1" }}>
              Develop programs to incentivize token usage, such as staking
              rewards, loyalty bonuses, and referral programs to drive adoption
              and increase transaction volume.
            </p>

            <div className="learn">
              Learn more{" "}
              <img
                src="https://sale.starchain.dev/assets/images/svg-icons/redirect.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solution;
