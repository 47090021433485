import React, { useEffect, useState } from "react";

function Insider() {






  return (
    <div className="insider p mx-2">
      <div className="">
        <div className="insider-title">
          <h3 lassName="text-uppercase">AMEYAA NXT ITO</h3>
          <span>
            {" "}
            <img
              src="https://sale.starchain.dev/assets/images/svg-icons/share.svg"
              alt=""
            />
          </span>
        </div>
        <p style={{ width: "100%" }} className="m-title">
          At Ameyaa NXT, we are committed to empowering everyone on their
          wellness journey by providing innovative tools and resources.
        </p>
        <p className="m-desc">
          In this ITO, only 12% of the total supply of 10 billion tokens will be
          made available. This allocation is designed to ensure a strong
          foundation for our ecosystem and to provide early supporters with an
          opportunity to be part of a revolutionary change in the wellness
          industry.
        </p>
      </div>
      <div className="">
        <div className="insider-title">
          <h3 lassName="text-uppercase">Personalized Plans</h3>
          <span>
            {" "}
            <img
              src="https://sale.starchain.dev/assets/images/svg-icons/share.svg"
              alt=""
            />
          </span>
        </div>
        <p style={{ width: "100%" }} className="m-title">
          Ameyaa NXT offers a Personalized Plan for Revenue Growth tailored to
          your unique investment goals and trading style.
        </p>
        <p className="m-desc">
          Using advanced AI algorithms, the platform analyzes your financial
          data, market trends, and risk tolerance to create a customized
          strategy that maximizes your potential for profit. The AI bot
          continuously monitors your progress, providing real-time
          recommendations and adjustments to optimize performance.
        </p>
      </div>
    </div>
  );
}

export default Insider;
