import React from "react";

function Footer() {
  return (
    <div className="row col-lg-12 p justify-content-between" style={{marginTop:'150px'}}>
      <div className="col-lg-3">
        <div>
          <img
            src="/image/AmeyaaLogo.jpeg"
            alt=""
            srcset=""
            style={{height:'80px'}}
          />
        </div>
        <div style={{ display: "flex", gap: "10px", paddingTop: "25px" }}>
          <img
            src="	https://sale.starchain.dev/assets/images/svg-icons/telegram.svg"
            alt=""
            srcset=""
          />
          <img
            src="https://sale.starchain.dev/assets/images/svg-icons/twitter.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div className="col-lg-3 footer">
        <a href="">Home</a>
        <a href="">About</a>
        <a href="">How to Buy</a>
        <a href="">Tokenomics</a>
        <a href="">Roadmap</a>
      </div>

      <div className="text-center mt-4 mb-3" style={{color:'#3ce83c',fontWeight:'300'}}>
       <u> Design & Developed By Chain Magician Lab Private Limited</u>
      </div>
    </div>
  );
}

export default Footer;
