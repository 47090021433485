import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AppRoutes from "./AppRoutes";
import Navbar from "./page/Navbar";
import Footer from "./page/Footer";
import Token from "./page/Token";
import Reward from "./page/Reward";

function App() {
  return (
    <div className="">
      <div className="">
        <Navbar />
        <Routes>
          <Route path="/" element={<AppRoutes />} />
          <Route path="/token" element={<Token />} />
          <Route path="/reward" element={<Reward />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
