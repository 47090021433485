import {
  readContract,
  writeContract,
  getBalance,
  waitForTransactionReceipt,
} from "@wagmi/core";
import { contract, contractAbi, token, tokenAbi } from "./config";
import { config } from "..";

export async function stakeBNB(amt, ref) {
  console.log(amt,":::::")
  const res = await writeContract(config,{
    abi: contractAbi,
    address: contract,
    functionName: "buyToken",
    args: [
      (amt * 1e18).toLocaleString("fullwide", { useGrouping: false }),
      ref,
    ],
  });
  return res;
}

export async function tokenApprove(amt) {
  const data = await writeContract(config,{
    abi: tokenAbi,
    address: token,
    functionName: "approve",
    args: [
      contract,
      (amt * 1e18).toLocaleString("fullwide", { useGrouping: false }),
    ],
  });
  console.log(data, ":::");
  const transactionReceipt = waitForTransactionReceipt(config,{
    hash: data,
  });
  return transactionReceipt;
}

export async function tokenAllowance(userAddress) {
  try {
    const data = await readContract(config,{
      abi: tokenAbi,
      address: token,
      functionName: "allowance",
      args: [userAddress, contract],
    });
    console.log(data, "check ");
    return Number(data._hex);
  } catch (e) {
    console.log(e);
  }
}

export async function getUserBalance(wallet) {
  const data = await getBalance(config,{
    address: wallet,
    token: token,
  });
  console.log(data,":::")
  return Number(data?.formatted);
}

export async function getowner() {
  try {
    const data = await readContract(config,{
      abi: contractAbi,
      address: contract,
      functionName: "owner",
      args: [],
    });
    console.log(data, "owner ");
    return data;
  } catch (e) {
    console.log(e);
  }
}
